import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table, Input, FormGroup, Label, Button, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { FaTrash } from 'react-icons/fa';
import Toggle from 'react-toggle';

function PickupSettingsModal(props) {
    const [activeTab, setActiveTab] = useState('event');
    const [rules, setRules] = useState({
        useAndConditions: true,
        eventDetails: {
            include: true,
            rules: []
        },
        areas: {
            include: true,
            rules: []
        },
        priority: {
            include: true,
            rules: []
        },
        age: {
            include: true,
            rules: []
        }
    });
    const [error, setError] = useState("");
    const [tenantData, setTenantData] = useState({ areas: [] });
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        if (props.tenantGroupId && props.region) {
            fetch(`${props.baseUrl}/tenantData?tenantGroupId=${props.tenantGroupId}&region=${props.region}`)
                .then(res => res.json())
                .then(data => {
                    setTenantData(data);
                    
                    // Load pickup rules
                    fetch(`${props.baseUrl}/pickupRules?tenantGroupId=${props.tenantGroupId}&region=${props.region}`)
                        .then(res => res.json())
                        .then(rulesData => {
                            if (rulesData.rules) {
                                setRules(rulesData.rules);
                            }
                        });
                })
                .catch(err => {
                    console.error("Failed to fetch data:", err);
                    setError("Failed to load data");
                });
        }
    }, [props.tenantGroupId, props.region, props.baseUrl]);

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const addEventRule = () => {
        const newRules = {...rules};
        newRules.eventDetails.rules.push({
            operator: "Equal To",
            value: ""
        });
        setRules(newRules);
        setHasChanges(true);
    };

    const addAreaRule = () => {
        const newRules = {...rules};
        newRules.areas.rules.push({
            area: null
        });
        setRules(newRules);
        setHasChanges(true);
    };

    const addPriorityRule = () => {
        const newRules = {...rules};
        newRules.priority.rules.push({
            operator: "Less Than",
            value: 0
        });
        setRules(newRules);
        setHasChanges(true);
    };

    const addAgeRule = () => {
        const newRules = {...rules};
        newRules.age.rules.push({
            operator: "Newer Than",
            value: 0
        });
        setRules(newRules);
        setHasChanges(true);
    };

    const deleteRule = (category, index) => {
        const newRules = {...rules};
        newRules[category].rules.splice(index, 1);
        setRules(newRules);
        setHasChanges(true);
    };

    const updateRule = (category, index, field, value) => {
        const newRules = {...rules};
        newRules[category].rules[index][field] = value;
        setRules(newRules);
        setHasChanges(true);
    };

    const toggleInclude = (category) => {
        const newRules = {...rules};
        newRules[category].include = !newRules[category].include;
        setRules(newRules);
        setHasChanges(true);
    };

    const saveRules = async () => {
        try {
            const response = await fetch(`${props.baseUrl}/pickupRules?tenantGroupId=${props.tenantGroupId}&region=${props.region}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(rules)
            });
            
            if (!response.ok) {
                throw new Error('Failed to save rules');
            }
            
            setHasChanges(false);
            setError("");
        } catch (err) {
            setError("Failed to save rules");
        }
    };

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} fullscreen={true} centered>
            <ModalHeader toggle={props.toggle}>
                Event Pickup Settings
            </ModalHeader>
            <ModalBody>
                <div className="mb-4">
                    <Label className="mr-2">Rules Evaluation:</Label>
                    <Toggle
                        checked={rules.useAndConditions}
                        onChange={() => {
                            setRules({...rules, useAndConditions: !rules.useAndConditions});
                            setHasChanges(true);
                        }}
                    />
                    <span className="ml-2">
                        {rules.useAndConditions ? "ALL conditions must match (AND)" : "ANY condition can match (OR)"}
                    </span>
                </div>

                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === 'event' })} onClick={() => toggleTab('event')}>
                            Event Type
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === 'areas' })} onClick={() => toggleTab('areas')}>
                            Area
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === 'priority' })} onClick={() => toggleTab('priority')}>
                            Priority
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === 'age' })} onClick={() => toggleTab('age')}>
                            Age
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={activeTab}>
                    <TabPane tabId="event">
                        <div className="mt-3 mb-3">
                            <Label className="mr-2">Event Title Rules:</Label>
                            <Toggle
                                checked={rules.eventDetails.include}
                                onChange={() => toggleInclude('eventDetails')}
                            />
                            <span className="ml-2">
                                {rules.eventDetails.include ? "Include matching events" : "Exclude matching events"}
                            </span>
                        </div>
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>Operator</th>
                                    <th>Value</th>
                                    <th className="text-center w-12">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rules.eventDetails.rules.map((rule, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Select
                                                options={[
                                                    { value: 'Equal To', label: 'Equal To' },
                                                    { value: 'Contains', label: 'Contains' }
                                                ]}
                                                value={{ value: rule.operator, label: rule.operator }}
                                                onChange={(selected) => updateRule('eventDetails', index, 'operator', selected.value)}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="text"
                                                value={rule.value}
                                                onChange={(e) => updateRule('eventDetails', index, 'value', e.target.value)}
                                            />
                                        </td>
                                        <td className="text-center">
                                            <button onClick={() => deleteRule('eventDetails', index)} className="p-1">
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Button onClick={addEventRule}>Add Rule</Button>
                    </TabPane>

                    <TabPane tabId="areas">
                        <div className="mt-3 mb-3">
                            <Label className="mr-2">Area Rules:</Label>
                            <Toggle
                                checked={rules.areas.include}
                                onChange={() => toggleInclude('areas')}
                            />
                            <span className="ml-2">
                                {rules.areas.include ? "Include matching areas" : "Exclude matching areas"}
                            </span>
                        </div>
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>Area</th>
                                    <th className="text-center w-12">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rules.areas.rules.map((rule, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Select
                                                options={tenantData.areas}
                                                value={rule.area}
                                                onChange={(selected) => updateRule('areas', index, 'area', selected)}
                                            />
                                        </td>
                                        <td className="text-center">
                                            <button onClick={() => deleteRule('areas', index)} className="p-1">
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Button onClick={addAreaRule}>Add Rule</Button>
                    </TabPane>

                    <TabPane tabId="priority">
                        <div className="mt-3 mb-3">
                            <Label className="mr-2">Priority Rules:</Label>
                            <Toggle
                                checked={rules.priority.include}
                                onChange={() => toggleInclude('priority')}
                            />
                            <span className="ml-2">
                                {rules.priority.include ? "Include matching priorities" : "Exclude matching priorities"}
                            </span>
                        </div>
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>Operator</th>
                                    <th>Value</th>
                                    <th className="text-center w-12">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rules.priority.rules.map((rule, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Select
                                                options={[
                                                    { value: 'Less Than', label: 'Less Than' },
                                                    { value: 'Greater Than', label: 'Greater Than' }
                                                ]}
                                                value={{ value: rule.operator, label: rule.operator }}
                                                onChange={(selected) => updateRule('priority', index, 'operator', selected.value)}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="number"
                                                value={rule.value}
                                                onChange={(e) => updateRule('priority', index, 'value', parseInt(e.target.value))}
                                            />
                                        </td>
                                        <td className="text-center">
                                            <button onClick={() => deleteRule('priority', index)} className="p-1">
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Button onClick={addPriorityRule}>Add Rule</Button>
                    </TabPane>

                    <TabPane tabId="age">
                        <div className="mt-3 mb-3">
                            <Label className="mr-2">Age Rules:</Label>
                            <Toggle
                                checked={rules.age.include}
                                onChange={() => toggleInclude('age')}
                            />
                            <span className="ml-2">
                                {rules.age.include ? "Include matching ages" : "Exclude matching ages"}
                            </span>
                        </div>
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>Operator</th>
                                    <th>Value (seconds)</th>
                                    <th className="text-center w-12">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rules.age.rules.map((rule, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Select
                                                options={[
                                                    { value: 'Newer Than', label: 'Newer Than' },
                                                    { value: 'Older Than', label: 'Older Than' }
                                                ]}
                                                value={{ value: rule.operator, label: rule.operator }}
                                                onChange={(selected) => updateRule('age', index, 'operator', selected.value)}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type="number"
                                                value={rule.value}
                                                onChange={(e) => updateRule('age', index, 'value', parseInt(e.target.value))}
                                            />
                                        </td>
                                        <td className="text-center">
                                            <button onClick={() => deleteRule('age', index)} className="p-1">
                                                <FaTrash />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Button onClick={addAgeRule}>Add Rule</Button>
                    </TabPane>
                </TabContent>
            </ModalBody>
            <ModalFooter>
                {error && <span className="text-danger">{error}</span>}
                <Button color="primary" onClick={saveRules} disabled={!hasChanges}>Save Rules</Button>
                <Button color="secondary" onClick={props.toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}

export default PickupSettingsModal;