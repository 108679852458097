import React, { useState, useCallback, useEffect } from 'react';
import Select from 'react-select';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table, Input, FormGroup, Label, Button, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames'; // If not installed, you can use `npm install classnames`
import { FaArrowUp, FaArrowDown, FaTrash } from 'react-icons/fa';


function GuidelinesModal(props) {
    const defaultTrigger = { subject: {}, operator: {}, theValue: {} };
    const defaultAction = { action: {}, theValue: {}, option: {} };
    const [guidelines, setGuidelines] = useState([]);
    const [showAddGuideline, setShowAddGuideline] = useState(false);
    const [triggers, setTriggers] = useState([defaultTrigger]);
    const [actions, setActions] = useState([defaultAction]);
    const [title, setTitle] = useState("");
    const [activeTab, setActiveTab] = useState('1');
    const [editingIndex, setEditingIndex] = useState(null);
    const [tenantData, setTenantData] = useState({ actions: [], actionCategories: [], actionPlans: [], actionTypes: [], areas: [] });
    const [error, setError] = useState("");
    const [guidelineListError, setGuidelineListError] = useState("");

    useEffect(() => {
        let foundEmptyTriggers = false;
        for (let i = 0; i < guidelines.length; i++) {
            if (foundEmptyTriggers) {
                setGuidelineListError(`'${guidelines[i - 1].title}' has no triggers. All Guidelines below it are unreachable.`);
                return;
            }
            if (guidelines[i].triggers.length === 0) {
                foundEmptyTriggers = true;
            }
        }
        setGuidelineListError("");
    }, [guidelines]);

    const updateActions = useCallback(() => {
        fetch(`${props.baseUrl}/tenantData?tenantGroupId=${props.tenantGroupId}&region=${props.region}`)
            .then(res => res.json())
            .then(data => {
                //console.log(data);
                setTenantData(data);
                setGuidelines(data.guidelines);
            })
            .catch(err => {
                console.error("Failed to fetch actions:", err);
            });
    }, [props.baseUrl, props.tenantGroupId, props.region]);

    const saveGuidelines = useCallback(async (updatedGuidelines) => {
        if (!props.tenantGroupId || !props.region) {
            console.warn("tenantGroupId or region is null, skipping save guidelines request.");
            return;
        }

        const url = `${props.baseUrl}/guidelines?tenantGroupId=${props.tenantGroupId}&region=${props.region}`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedGuidelines)
            });
            const data = await response.json();
            if (data.status === "success") {
                console.log(data.message);
            } else {
                console.error("Failed to save guidelines:", data.message);
            }
        } catch (err) {
            console.error("Failed to save guidelines:", err);
        }
    }, [props.baseUrl, props.tenantGroupId, props.region]);

    useEffect(() => {
        updateActions();
    }, [updateActions]);

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const addTrigger = () => {
        setTriggers([...triggers, defaultTrigger]);
    };

    const addAction = () => {
        setActions([...actions, defaultAction]);
    };

    const onCancel = () => {
        setShowAddGuideline(false);
        setEditingIndex(null);
        setTitle("");
        setTriggers([defaultTrigger]);
        setActions([defaultAction]);
    }

    const deleteGuideline = (index) => {
        const newGuidelines = [...guidelines];
        newGuidelines.splice(index, 1);
        setGuidelines(newGuidelines);
        saveGuidelines(newGuidelines); // Pass the updated guidelines
        setShowAddGuideline(false);
        setEditingIndex(null);
        setTitle("");
        setTriggers([defaultTrigger]);
        setActions([defaultAction]);
        setActiveTab('1');
    };

    const saveGuideline = () => {
        validateGuideline();

        if (!isGuidelineValid()) return;
        let updatedGuidelines;
        if (editingIndex !== null) {
            // We're updating an existing guideline
            updatedGuidelines = [...guidelines];
            updatedGuidelines[editingIndex] = { title, triggers, actions };
        } else {
            // We're adding a new guideline
            updatedGuidelines = [...guidelines, { title, triggers, actions }];
        }
        setGuidelines(updatedGuidelines);
        saveGuidelines(updatedGuidelines); // Pass the updated guidelines
        // Reset the modal state
        setShowAddGuideline(false);
        setTitle("");
        setTriggers([defaultTrigger]);
        setActions([defaultAction]);
        setEditingIndex(null);
        setActiveTab('1');
    };

    const validateGuideline = useCallback(() => {
        // Check triggers first
        if (triggers.some(trigger => !trigger.subject.value)) {
            setError("All triggers must have a subject set.");
            return;
        }
        if (triggers.some(trigger => !trigger.theValue.value)) {
            setError("All triggers must have a value set.");
            return;
        }
        // Check if at least one action is set
        if (actions.length === 0) {
            setError("At least one Action must be set");
            return;
        }
        // Check for concluding actions
        for (let i = 0; i < actions.length; i++) {
            const action = actions[i];
            const dictionaryAction = GuidelineDictionary.actions.find(a => a.value === action.action.value);

            if (dictionaryAction && dictionaryAction.concluding) {
                if (i !== actions.length - 1) {
                    // There are actions after this concluding action
                    setError(`'${dictionaryAction.label}' is a Concluding Action, and must be the last Action in the list`);
                    return;
                }
            }
        }
        if (actions.some(action => !action.action.value)) {
            setError("All Actions must be populated");
            return;
        }
        const mutuallyExclusiveActions = actions.filter(action => {
            const dictionaryAction = GuidelineDictionary.actions.find(a => a.value === action.action.value);
            return dictionaryAction && dictionaryAction.mutuallyExclusiveAction;
        });
        if (mutuallyExclusiveActions.length > 1) {
            const firstAction = GuidelineDictionary.actions.find(a => a.value === mutuallyExclusiveActions[0].action.value).label;
            const secondAction = GuidelineDictionary.actions.find(a => a.value === mutuallyExclusiveActions[1].action.value).label;
            setError(`'${firstAction}' cannot be combined with '${secondAction}'`);
            return;
        }
        // Check for actions requiring a 'step' trigger and a non-empty 'theValue'
        for (let i = 0; i < actions.length; i++) {
            const action = actions[i];
            const dictionaryAction = GuidelineDictionary.actions.find(a => a.value === action.action.value);

            if (dictionaryAction && dictionaryAction.requiresStepTrigger) {
                const hasStepTrigger = triggers.some(trigger => trigger.subject.value === 'step');
                if (!hasStepTrigger) {
                    setError(`'${dictionaryAction.label}' requires an 'Individual Action' trigger.`);
                    return;
                }
            }

            if (dictionaryAction && dictionaryAction.requiresValue && (!action.theValue.value || action.theValue.value.trim() === '')) {
                setError(`'${dictionaryAction.label}' requires a non-empty value.`);
                return;
            }

            if (dictionaryAction && dictionaryAction.offerEventAdjust && action.theValue.updateEventPriority) {

                if (action.theValue.maxPriority < 1 || action.theValue.minPriority < 1 || action.theValue.maxPriority > 1000 || action.theValue.minPriority > 1000) {
                    setError(`'${dictionaryAction.label}' priorities must be between 1-1000.`);
                    return;
                } else if (action.theValue.maxPriority < action.theValue.minPriority) {
                    setError(`'${dictionaryAction.label}' minimum priority must be below maximum.`);
                    return;
                }

            }

        }
        if (title.trim() === "") {
            setError("Title is not set.");
            return;
        }
        setError(""); // Clear error if everything is fine
    }, [actions, title, triggers]);

    useEffect(() => {
        validateGuideline();
    }, [title, triggers, actions, validateGuideline]);

    const isGuidelineValid = () => {
        return error === "";
    };

    const handleEditGuideline = (index) => {
        const guideline = guidelines[index];
        setTitle(guideline.title);
        setTriggers(guideline.triggers);
        setActions(guideline.actions);
        setEditingIndex(index);
        setActiveTab('1');
        setShowAddGuideline(true);
    };

    const moveUp = (index, event) => {
        event.stopPropagation();
        const newGuidelines = [...guidelines];
        const temp = newGuidelines[index - 1];
        newGuidelines[index - 1] = newGuidelines[index];
        newGuidelines[index] = temp;
        setGuidelines(newGuidelines);
        saveGuidelines(newGuidelines); // Pass the updated guidelines
    };

    const moveDown = (index, event) => {
        event.stopPropagation();
        const newGuidelines = [...guidelines];
        const temp = newGuidelines[index + 1];
        newGuidelines[index + 1] = newGuidelines[index];
        newGuidelines[index] = temp;
        setGuidelines(newGuidelines);
        saveGuidelines(newGuidelines); // Pass the updated guidelines
    };

    const deleteTrigger = (index) => {
        const newTriggers = [...triggers];
        newTriggers.splice(index, 1);
        setTriggers(newTriggers);
    }

    const deleteAction = (index) => {
        const newActions = [...actions];
        newActions.splice(index, 1);
        setActions(newActions);
    }


    const handleClose = () => {
        props.toggle();
    }

    const availableTriggers = GuidelineDictionary.triggers.filter(option => !triggers.some(trigger => trigger.subject.value === option.value));
    const availableActions = GuidelineDictionary.actions.filter(option => !actions.some(action => action.action.value === option.value));

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} fullscreen={true} centered>
            <ModalHeader toggle={props.toggle}>Virtual Operator Guidelines</ModalHeader>
            <ModalBody style={{ height: '420px', overflowY: 'auto' }} className='px-4 pb-4 pt-2'> {/* Static Height here */}
                <Table striped>
                    <thead>
                        <tr>
                            <th className="w-2/3">Title</th> {/* This takes up 2/3 of the row width */}
                            <th className="text-center">Triggers</th> {/* This takes up 1/6 of the row width */}
                            <th className="text-center">Actions</th> {/* This takes up 1/6 of the row width */}
                            <th className="text-center">Reorder</th> {/* New column for reordering buttons */}
                        </tr>
                    </thead>
                    <tbody>
                        {guidelines.map((guideline, index) => (
                            <tr key={index} onClick={() => handleEditGuideline(index)}>
                                <td>{guideline.title}</td>
                                <td className="text-center">{guideline.triggers.length}</td>
                                <td className="text-center">{guideline.actions.length}</td>
                                <td className="text-center"> {/* Column for reordering buttons */}
                                    {index > 0 &&
                                        <button onClick={(e) => moveUp(index, e)} className="p-1">
                                            <FaArrowUp />
                                        </button>
                                    }
                                    {index < guidelines.length - 1 &&
                                        <button onClick={(e) => moveDown(index, e)} className="p-1">
                                            <FaArrowDown />
                                        </button>
                                    }
                                </td>


                            </tr>
                        ))}
                    </tbody>

                </Table>
                {/* Conditional rendering for the note */}
                <p className="mt-4 text-center text-sm">
                    Guidelines allow you to specify how a Virtual Operator should process an event.
                    <br />
                    Each Guideline is checked sequentially every time a Virtual Operator acts.
                    <br />
                    The first Guideline for which all Triggers are met will have its actions ran.
                    <br />
                    For best results, perform thorough testing to refine these as needed.
                </p>
                {/* Add/Edit Guideline Modal */}
                <Modal isOpen={showAddGuideline} toggle={() => setShowAddGuideline(false)} fullscreen={true} size='xl' centered> {/* Static Width here */}

                    <ModalHeader toggle={() => setShowAddGuideline(false)}>
                        {editingIndex === null ? "Add Guideline" : "Edit Guideline"}
                    </ModalHeader>
                    <ModalBody style={{ height: '400px', overflowY: 'auto' }} className='px-4 pb-4 pt-2'> {/* Static Height here */}
                        <FormGroup>
                            <Label>Title</Label>
                            <Input type="text" value={title} onChange={e => setTitle(e.target.value)} />
                        </FormGroup>

                        <Nav tabs>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggleTab('1'); }}>
                                    Triggers
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggleTab('2'); }}>
                                    Actions
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <FormGroup>
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>Subject</th>
                                                <th>Operator</th>
                                                <th>Value</th>
                                                <th className="text-center w-12">Delete</th> {/* Modify width here */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {triggers.map((trigger, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <Select
                                                            options={availableTriggers}
                                                            onChange={selected => {
                                                                let newTriggers = [...triggers];
                                                                newTriggers[index].subject = selected;
                                                                newTriggers[index].operator = {}
                                                                if (selected.operators) {
                                                                    newTriggers[index].operator = selected.operators[0];
                                                                }
                                                                newTriggers[index].theValue = {}
                                                                setTriggers(newTriggers);
                                                            }}
                                                            value={trigger.subject}
                                                        />
                                                    </td>
                                                    <td>
                                                        {trigger.subject.operators &&
                                                            <Select
                                                                options={trigger.subject.operators}
                                                                onChange={selected => {
                                                                    let newTriggers = [...triggers];
                                                                    newTriggers[index].operator = selected;
                                                                    setTriggers(newTriggers);
                                                                }}
                                                                value={trigger.operator}
                                                            />
                                                        }
                                                    </td>
                                                    <td>
                                                        {trigger.subject.dataset ?
                                                            <Select
                                                                options={tenantData[trigger.subject.dataset]}
                                                                onChange={selected => {
                                                                    let newTriggers = [...triggers];
                                                                    newTriggers[index].theValue = selected;
                                                                    setTriggers(newTriggers);
                                                                }}
                                                                value={trigger.theValue}
                                                            />
                                                            :
                                                            <>
                                                                <Select
                                                                    options={trigger.subject.associatedOptions || []}
                                                                    onChange={selected => {
                                                                        let newTriggers = [...triggers];
                                                                        newTriggers[index].theValue = selected;
                                                                        if (selected.showNumber) {
                                                                            newTriggers[index].theValue.numberValue = newTriggers[index].theValue.numberValue || 0;
                                                                        }
                                                                        setTriggers(newTriggers);
                                                                    }}
                                                                    value={trigger.theValue}
                                                                />
                                                                {trigger.subject.associatedOptions &&
                                                                    trigger.theValue.showNumber && (
                                                                        <Input
                                                                            type="number"
                                                                            className="mt-2"
                                                                            value={trigger.theValue.numberValue || 0}
                                                                            onChange={e => {
                                                                                let newTriggers = [...triggers];
                                                                                newTriggers[index].theValue.numberValue = parseInt(e.target.value);
                                                                                setTriggers(newTriggers);
                                                                            }}
                                                                            min="0"
                                                                        />
                                                                    )}
                                                            </>
                                                        }
                                                    </td>
                                                    <td className="text-center w-12"> {/* Modify width here */}
                                                        <button onClick={(e) => { e.stopPropagation(); deleteTrigger(index); }} className="p-1 pt-2">
                                                            <FaTrash />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Button onClick={addTrigger}>Add Trigger</Button>
                                </FormGroup>
                            </TabPane>
                            <TabPane tabId="2">
                                <FormGroup>
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>Action</th>
                                                <th>Value</th>
                                                {actions.some(action => action.action && action.action.associatedOptions) && <th>Options</th>}
                                                <th className="text-center w-12">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {actions.map((action, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <Select
                                                            options={availableActions}
                                                            onChange={selected => {
                                                                let newActions = [...actions];
                                                                newActions[index].action = selected;
                                                                if (selected.associatedOptions) {
                                                                    newActions[index].option = selected.associatedOptions[0];
                                                                }
                                                                setActions(newActions);
                                                            }}
                                                            value={action.action}
                                                        />
                                                    </td>
                                                    <td>
                                                        {action.action.requiresValue &&
                                                            <Input
                                                                type="text"
                                                                value={action.theValue.value}
                                                                onChange={e => {
                                                                    let newActions = [...actions];
                                                                    newActions[index].theValue.value = e.target.value;
                                                                    newActions[index].theValue.label = e.target.value;
                                                                    setActions(newActions);
                                                                }}
                                                            />
                                                        }
                                                        {action.action.dataset &&
                                                            <Select
                                                                options={tenantData[action.action.dataset]}
                                                                onChange={selected => {
                                                                    let newActions = [...actions];
                                                                    newActions[index].theValue = selected;
                                                                    setActions(newActions);
                                                                }}
                                                                value={action.theValue}
                                                            />
                                                        }
                                                        {action.action.offerEventAdjust &&
                                                            <div className="space-y-2">
                                                                {/* Checkbox for "Update Event Type" with a smaller label above */}
                                                                <div>
                                                                    <div className="text-xs text-gray-400">Update Event Type</div>
                                                                    <label className="flex items-center space-x-2">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={action.theValue.updateEventType || false}
                                                                            onChange={e => {
                                                                                let newActions = [...actions];
                                                                                newActions[index].theValue = {
                                                                                    ...newActions[index].theValue,
                                                                                    updateEventType: e.target.checked
                                                                                };
                                                                                setActions(newActions);
                                                                            }}
                                                                        />
                                                                        <span className="text-sm">Enable</span>
                                                                    </label>
                                                                </div>

                                                                {/* Checkbox for "Update Event Priority" with a smaller label above */}
                                                                <div>
                                                                    <div className="text-xs text-gray-400">Update Event Priority</div>
                                                                    <label className="flex items-center space-x-2">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={action.theValue.updateEventPriority || false}
                                                                            onChange={e => {
                                                                                let newActions = [...actions];
                                                                                newActions[index].theValue = {
                                                                                    ...newActions[index].theValue,
                                                                                    updateEventPriority: e.target.checked
                                                                                };
                                                                                setActions(newActions);
                                                                            }}
                                                                        />
                                                                        <span className="text-sm">Enable</span>
                                                                    </label>
                                                                </div>

                                                                {/* Min and Max Priority Inputs with a single title */}
                                                                {action.theValue.updateEventPriority && (
                                                                    <div>
                                                                        <div className="text-xs text-gray-400 mb-1">Priority Range</div>
                                                                        <label className="flex items-center space-x-2">
                                                                            <input
                                                                                type="number"
                                                                                className="w-20 p-1 rounded text-black"
                                                                                placeholder="Min"
                                                                                value={action.theValue.minPriority || null}
                                                                                onChange={e => {
                                                                                    let newActions = [...actions];
                                                                                    newActions[index].theValue = {
                                                                                        ...newActions[index].theValue,
                                                                                        minPriority: Number(e.target.value)
                                                                                    };
                                                                                    setActions(newActions);
                                                                                }}
                                                                            />
                                                                            <span className="text-sm">-</span>
                                                                            <input
                                                                                type="number"
                                                                                className="w-20 p-1 rounded text-black"
                                                                                placeholder="Max"
                                                                                value={action.theValue.maxPriority || null}
                                                                                onChange={e => {
                                                                                    let newActions = [...actions];
                                                                                    newActions[index].theValue = {
                                                                                        ...newActions[index].theValue,
                                                                                        maxPriority: Number(e.target.value)
                                                                                    };
                                                                                    setActions(newActions);
                                                                                }}
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        }

                                                    </td>
                                                    {action.action && action.action.associatedOptions ? (
                                                        <td>
                                                            <Select
                                                                options={action.action.associatedOptions}
                                                                value={action.option}
                                                                onChange={selected => {
                                                                    let newActions = [...actions];
                                                                    newActions[index].option = selected;
                                                                    setActions(newActions);
                                                                }}
                                                            />
                                                        </td>
                                                    ) : actions.some(act => act.action && act.action.associatedOptions) && <td />}
                                                    <td className="text-center w-12">
                                                        <button onClick={(e) => { e.stopPropagation(); deleteAction(index); }} className="p-1 pt-2">
                                                            <FaTrash />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Button onClick={addAction}>Add Action</Button>
                                </FormGroup>
                            </TabPane>
                        </TabContent>
                    </ModalBody>
                    <ModalFooter>
                        {/* Display the error message */}
                        <span className="text-danger text-sm">{error}</span>
                        {editingIndex !== null && <Button color="danger" onClick={() => deleteGuideline(editingIndex)}>Delete</Button>}
                        <Button color="primary" onClick={saveGuideline} disabled={!isGuidelineValid()}>Save</Button>
                        <Button color="secondary" onClick={onCancel}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </ModalBody>
            <ModalFooter>
                <span className="text-danger text-sm">{guidelineListError}</span>
                <Button color="primary" onClick={() => { setShowAddGuideline(true); validateGuideline(); }}>Add Guideline</Button>
                <button className="btn btn-secondary" onClick={handleClose}>Close</button>
            </ModalFooter>
        </Modal>
    );
}

export default GuidelinesModal;

const GuidelineDictionary = { 
    triggers: [
        {
            label: "Person",
            value: "person",
            associatedOptions: [
                {
                    label: "Seen",
                    value: "seen"
                },
                {
                    label: "Not Seen",
                    value: "not_seen"
                },
                {
                    label: "Count Above",
                    value: "count_above",
                    showNumber: true
                },
                {
                    label: "Count Below",
                    value: "count_below",
                    showNumber: true
                }
            ]
        },
        {
            label: "Vehicle",
            value: "vehicle",
            associatedOptions: [
                {
                    label: "Seen",
                    value: "seen"
                },
                {
                    label: "Not Seen",
                    value: "not_seen"
                },
                {
                    label: "Count Above",
                    value: "count_above",
                    showNumber: true
                },
                {
                    label: "Count Below",
                    value: "count_below",
                    showNumber: true
                }
            ]
        },
        {
            label: "Individual Action",
            value: "step",
            operators: [
                {
                    label: "Equal To",
                    value: "equal_to"
                },
            ],
            dataset: "actions",
        },
        {
            label: "Action Category",
            value: "category",
            operators: [
                {
                    label: "Equal To",
                    value: "equal_to"
                },
                {
                    label: "Not Equal To",
                    value: "not_equal_to"
                },
            ],
            dataset: "actionCategories",
        },
        {
            label: "Action Type",
            value: "actionType",
            operators: [
                {
                    label: "Equal To",
                    value: "equal_to"
                },
                {
                    label: "Not Equal To",
                    value: "not_equal_to"
                },
            ],
            dataset: "actionTypes",
        },
        {
            label: "Area",
            value: "area",
            operators: [
                {
                    label: "Equal To",
                    value: "equal_to"
                },
                {
                    label: "Not Equal To",
                    value: "not_equal_to"
                },
            ],
            dataset: "areas"
        },
        { // xxx
            label: "Video Error",
            value: "video_error",
            associatedOptions: [
                {
                    label: "For Clip(s)",
                    value: "clip"
                },
                {
                    label: "For Live Stream(s)",
                    value: "live"
                },
                {
                    label: "For Either",
                    value: "either"
                },
            ]
        },
    ],
    actions: [
        {
            label: "Summarize Event",
            value: "summarize",
            offerEventAdjust: true
        },
        {
            label: "Escalate & Leave Event",
            value: "escalate",
            concluding: true,
            offerEventAdjust: true
        },
        {
            label: "Dispatch Guard",
            value: "dispatch"
        },
        {
            label: "Leave Event",
            value: "leave",
            concluding: true
        },
        {
            label: "Complete Action",
            value: "complete_action",
            mutuallyExclusiveAction: true,
        },
        {
            label: "Enforce Action Answer",
            value: "enforce_answer",
            requiresValue: true,
            requiresStepTrigger: true,
            mutuallyExclusiveAction: true,
        },
        {
            label: "Close Event",
            value: "close_event",
            concluding: true,
            associatedOptions: [
                {
                    label: "Otherwise Leave",
                    value: "leave",
                },
                {
                    label: "Otherwise Escalate",
                    value: "escalate",
                },
            ],
        },
        {
            label: "Enforce Closure Code",
            value: "enforce_closure",
            dataset: "closureCodes",
            concluding: true,
            associatedOptions: [
                {
                    label: "Otherwise Leave",
                    value: "leave",
                },
                {
                    label: "Otherwise Escalate",
                    value: "escalate",
                },
            ],
        },
        { // xxx
            label: "Force Audit Trail Entry",
            value: "audit_entry",
            requiresValue: true,
        }
    ]
}