import React, { useState, useRef, useEffect } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { createPortal } from 'react-dom';

function SectionHeaderTooltip({ title, tooltip, className = "", size = "small" }) {
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipStyle, setTooltipStyle] = useState({});
    const iconRef = useRef(null);
    
    const textSizeClass = size === "large" ? "text-lg" : "text-sm";
    const iconSize = size === "large" ? 16 : 14;

    const updatePosition = () => {
        if (iconRef.current) {
            setTooltipStyle({
                position: 'fixed',
                right: '24px',
                bottom: '16px',
                left: 'auto',
                top: 'auto',
                maxWidth: '300px',
                whiteSpace: 'pre-wrap'  // Add this to respect line breaks
            });
        }
    };

    useEffect(() => {
        if (showTooltip) {
            updatePosition();
            window.addEventListener('scroll', updatePosition);
            window.addEventListener('resize', updatePosition);
            
            return () => {
                window.removeEventListener('scroll', updatePosition);
                window.removeEventListener('resize', updatePosition);
            };
        }
    }, [showTooltip]);

    return (
        <div className={`flex items-center gap-2 ${className}`}>
            <span className={`${textSizeClass} font-semibold flex items-center gap-2`}>
                {title}
                <div 
                    className="relative inline-block"
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    ref={iconRef}
                >
                    <FaQuestionCircle 
                        size={iconSize}
                        className="text-gray-400 hover:text-gray-300 cursor-help" 
                    />
                    {showTooltip && createPortal(
                        <div 
                            className="z-50 p-2 bg-gray-900 text-white text-sm rounded shadow-lg"
                            style={tooltipStyle}
                        >
                            {tooltip}
                        </div>,
                        document.body
                    )}
                </div>
            </span>
        </div>
    );
}

export default SectionHeaderTooltip;