import 'tailwindcss/tailwind.css';
import React, { useState, useEffect, useCallback } from 'react';
import { FaMicrophone, FaMicrophoneSlash, FaCheck, FaTimes, FaSpinner } from 'react-icons/fa';
import axios from 'axios';

function Cases() {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [tenantGroupId, setTenantGroupId] = useState(null);
  const [region, setRegion] = useState(null);
  const [caseId, setCaseId] = useState(null);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [toolCalls, setToolCalls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [previousPromptSubmitted, setPreviousPromptSubmitted] = useState('');

  const baseUrl =  'https://virtualoperator.eastus.cloudapp.azure.com/api';  // 'http://127.0.0.1:5001';  // 

  const recognition = new window.webkitSpeechRecognition();
  recognition.continuous = false;
  recognition.interimResults = true;

  useEffect(() => {
    if (toolCalls.length === 0) {
      setTranscript("");
    }
  }, [toolCalls]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const tenantId = url.searchParams.get('tenantGroupId');
    setTenantGroupId(tenantId);
    const regionCode = url.searchParams.get('region');
    setRegion(regionCode);
    const caseId = url.searchParams.get('eventId');
    setCaseId(caseId);
    if (!tenantId || !regionCode || !caseId) {
      setError("No Tenant/Case Specified");
    }
  }, []);

  const startListening = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(() => {
        setIsListening(true);
        recognition.start();
      })
      .catch((err) => {
        console.error('Error accessing microphone:', err);
        if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
          navigator.mediaDevices.getUserMedia({ audio: true })
            .then(() => {
              setIsListening(true);
              recognition.start();
            })
            .catch((err) => {
              console.error('Error accessing microphone:', err);
              alert('Microphone permission denied. Please allow microphone access to use this feature.');
            });
        }
      });
  };

  const stopListening = () => {
    setIsListening(false);
    recognition.abort();
    recognition.stop();
  };

  recognition.onend = () => {
    setIsListening(false);
    recognition.abort();
    recognition.stop();
  };

  recognition.onresult = (event) => {
    setTranscript((prevTranscript) => {
      let interimTranscript = '';
      let currentTranscript = prevTranscript;
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcription = event.results[i][0].transcript;
        if (!event.results[i].isFinal) {
          interimTranscript += transcription;
        }
      }
      const currentTranscriptLower = currentTranscript.toLowerCase().replace(/-/g, ' ');
      const interimTranscriptLower = interimTranscript.toLowerCase().replace(/-/g, ' ');
      let overlap = -1;
      for (let i = 0; i < currentTranscriptLower.length; i++) {
        for (let j = interimTranscriptLower.length - 1; j >= 0; j--) {
          if (currentTranscriptLower.slice(i) === interimTranscriptLower.slice(0, j + 1)) {
            overlap = i;
            break;
          }
        }
        if (overlap !== -1) {
          break;
        }
      }
      if (overlap !== -1) {
        currentTranscript = currentTranscript.slice(0, overlap) + interimTranscript;
      } else {
        if (currentTranscript !== '') {
          currentTranscript += ' ';
        }
        currentTranscript += interimTranscript;
      }
      return currentTranscript.replace(/\s+/g, ' ').trim();
    });
  };

  recognition.onerror = (event) => {
    console.error('Speech recognition error:', event.error);
  };

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      setPreviousPromptSubmitted(transcript);
      const response = await axios.post(`${baseUrl}/processCase?tenantGroupId=${tenantGroupId}&region=${region}`, {
        caseId,
        prompt: transcript
      });
      setResponse(response.data.message.content);
      const toolCallsData = response.data.message.tool_calls;
      const parsedToolCalls = toolCallsData.map((toolCall) => {
        const parsedArguments = JSON.parse(toolCall.function.arguments);
        return Object.entries(parsedArguments);
      }).flat();
      setToolCalls(parsedToolCalls);
    } catch (error) {
      console.error('Error submitting case:', error);
    }
    setIsLoading(false);
  }, [caseId, region, tenantGroupId, transcript]);

  const promptAlreadySubmitted = previousPromptSubmitted === transcript;

  useEffect(() => {
    if (transcript && !promptAlreadySubmitted && transcript.toLowerCase().trim().endsWith('submit') && !isLoading) {
      setTimeout(() => {
        if (transcript.toLowerCase().trim().endsWith('submit') && !isLoading) {
          handleSubmit();
        }
      }, 2000);
    }
  }, [transcript, isLoading, handleSubmit, promptAlreadySubmitted]);

  const handleConfirmChange = async (field, value) => {
    try {
      const response = await axios.post(`${baseUrl}/confirmCaseChange?tenantGroupId=${tenantGroupId}&region=${region}&caseId=${caseId}`, {
        field,
        value,
        caseId
      });
      if (response.status === 200) {
        setToolCalls(toolCalls.filter(([f]) => f !== field));
      }
    } catch (error) {
      console.error('Error confirming case change:', error);
    }
  };

  const handleConfirmAllChanges = async () => {
    try {
      for (const [field, value] of toolCalls) {
        const response = await axios.post(`${baseUrl}/confirmCaseChange?tenantGroupId=${tenantGroupId}&region=${region}&caseId=${caseId}`, {
          field,
          value,
          caseId
        });
        if (response.status === 200) {
          setToolCalls(prevToolCalls => prevToolCalls.filter(([f]) => f !== field));
        }
      }
    } catch (error) {
      console.error('Error confirming all changes:', error);
    }
  };

  const handleDeclineChange = (field) => {
    setToolCalls(toolCalls.filter(([f]) => f !== field));
  };

  const handleClear = () => {
    setResponse(null);
    setToolCalls([]);
    setTranscript('');
  };

  return (
    <div className="bg-neutral-900 text-white min-h-screen flex items-center justify-center overflow-auto py-4">
      {error ? (
        <div className="mx-6 bg-neutral-800 p-4 pt-3 rounded-lg shadow-lg">
          <div className="text-red-500 text-lg font-bold mb-0 mt-1">{error}</div>
        </div>
      ) : (
        <div className="max-w-md w-full">
          <div className="bg-neutral-800 p-4 rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-2">
              <h1 className="text-2xl font-bold">Case Processing</h1>
            </div>
            {response && (
              <div className="mt-2">
                {response.split('\n').map((line, index) => (
                  <p key={index} className="mb-2 text-md">{line}</p>
                ))}
              </div>
            )}
            {toolCalls.length > 0 && (
              <div className="mt-2">
                <h2 className="text-sm text-zinc-300 italic mb-2">Suggested Changes</h2>
                <ul className='pl-4'>
                  {toolCalls.map(([field, value], index) => (
                    <li key={index} className="mb-2 flex items-center justify-between">
                      <div>
                        <div className="text-sm text-gray-400">{field}</div>
                        <div className="text-md">{value}</div>
                      </div>
                      <div>
                        <button
                          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-2 rounded-full focus:outline-none mr-2"
                          onClick={() => handleConfirmChange(field, value)}
                        >
                          <FaCheck size={8} />
                        </button>
                        <button
                          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-2 rounded-full focus:outline-none"
                          onClick={() => handleDeclineChange(field)}
                        >
                          <FaTimes size={8} />
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {!(response || toolCalls.length > 0) && (
              <div className="mt-2 mb-2">
                <textarea
                  className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                  rows="3"
                  placeholder="Ask questions about, or update the case..."
                  value={transcript}
                  onChange={(e) => setTranscript(e.target.value)}
                ></textarea>
              </div>
            )}
            <div className="flex justify-end items-center">

              {(response || toolCalls.length > 0) ? (
                <>
                  {toolCalls.length > 1 && (
                    <button
                      className="mr-4 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                      onClick={handleConfirmAllChanges}
                    >
                      Confirm All
                    </button>
                  )}
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    onClick={handleClear}
                  >
                    Try Another Request
                  </button>
                </>

              ) : (
                <>
                  <button
                    className={`${isListening ? 'bg-red-500' : 'bg-green-500'
                      } hover:bg-red-600 text-white font-bold py-2 px-2 rounded-full focus:outline-none mr-4`}
                    onClick={isListening ? stopListening : startListening}
                  >
                    {isListening ? <FaMicrophoneSlash size={20} /> : <FaMicrophone size={20} />}
                  </button>
                  <button
                    className={`bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center justify-center ${isLoading ? 'cursor-not-allowed' : ''}`}
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <FaSpinner className="animate-spin mr-2" size={16} />
                    ) : null}
                    Submit
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Cases;