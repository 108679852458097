import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GuidelinesModal from './GuidelinesModal';
import PickupSettingsModal from './PickupSettingsModal';
import SectionHeaderTooltip from './SectionHeaderTooltip';
import '../App.css';

function Settings() {
  const [generalGuidance, setGeneralGuidance] = useState("");
  const [secondsToWait, setSecondsToWait] = useState(10);
  const [storedGeneralGuidance, setStoredGeneralGuidance] = useState("");
  const [storedSecondsToWait, setStoredSecondsToWait] = useState(10);
  const [includeEmailText, setIncludeEmailText] = useState(false);
  const [storedIncludeEmailText, setStoredIncludeEmailText] = useState(false);
  const [includeEmailMedia, setIncludeEmailMedia] = useState(false);
  const [storedIncludeEmailMedia, setStoredIncludeEmailMedia] = useState(false);
  const [showModelSelection, setShowModelSelection] = useState(false);
  const [useGpt4, setUseGpt4] = useState(false);
  const [storedUseGpt4, setStoredUseGpt4] = useState(false);
  const [storedAllowDispatchGuards, setStoredAllowDispatchGuards] = useState(false);
  const [allowDispatchGuards, setAllowDispatchGuards] = useState(false);
  const [storedAllowMultiDispatch, setStoredAllowMultiDispatch] = useState(true);
  const [allowMultiDispatch, setAllowMultiDispatch] = useState(true);
  const [storedMaxDispatchDistance, setStoredMaxDispatchDistance] = useState(10);
  const [maxDispatchDistance, setMaxDispatchDistance] = useState(10);
  const [storedAllowWaitFunction, setStoredAllowWaitFunction] = useState(false);
  const [allowWaitFunction, setAllowWaitFunction] = useState(false);
  const [storedMaxWaitSeconds, setStoredMaxWaitSeconds] = useState(10);
  const [maxWaitSeconds, setMaxWaitSeconds] = useState(10);
  const [storedMinWaitSeconds, setStoredMinWaitSeconds] = useState(10);
  const [minWaitSeconds, setMinWaitSeconds] = useState(10);
  const [storedTotalMaxWaitSeconds, setStoredTotalMaxWaitSeconds] = useState(10);
  const [totalMaxWaitSeconds, setTotalMaxWaitSeconds] = useState(10);
  const [hasChanges, setHasChanges] = useState(false);
  const [activeTab, setActiveTab] = useState('response');
  const [tenantGroupId, setTenantGroupId] = useState(null);
  const [region, setRegion] = useState(null);
  const [guidelinesModalOpen, setGuidelinesModalOpen] = useState(false);
  const [virtualAssistEnabled, setVirtualAssistEnabled] = useState(false);
  const [storedVirtualAssistEnabled, setStoredVirtualAssistEnabled] = useState(false);
  const [virtualOperatorEnabled, setVirtualOperatorEnabled] = useState(false);
  const [storedVirtualOperatorEnabled, setStoredVirtualOperatorEnabled] = useState(false);
  const [maxSummaryWords, setMaxSummaryWords] = useState(10);
  const [storedMaxSummaryWords, setStoredMaxSummaryWords] = useState(10);
  const [minSummaryWords, setMinSummaryWords] = useState(10);
  const [storedMinSummaryWords, setStoredMinSummaryWords] = useState(10);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [allowPausing, setAllowPausing] = useState(false);
  const [storedAllowPausing, setStoredAllowPausing] = useState(false);
  const [numberOfOperators, setNumberOfOperators] = useState(5);
  const [storedNumberOfOperators, setStoredNumberOfOperators] = useState(5);
  const [pickupSettingsModalOpen, setPickupSettingsModalOpen] = useState(false);
  const [enableClips, setEnableClips] = useState(true);
const [storedEnableClips, setStoredEnableClips] = useState(true);
const [enableLiveVideo, setEnableLiveVideo] = useState(true);
const [storedEnableLiveVideo, setStoredEnableLiveVideo] = useState(true);
const [canCompleteActions, setCanCompleteActions] = useState(true);
const [storedCanCompleteActions, setStoredCanCompleteActions] = useState(true);
const [canEscalate, setCanEscalate] = useState(true);
const [storedCanEscalate, setStoredCanEscalate] = useState(true);
const [canCloseEvent, setCanCloseEvent] = useState(true);
const [storedCanCloseEvent, setStoredCanCloseEvent] = useState(true);
  const [error, setError] = useState(null);
  const tooltips = {
    eventMedia: "Control which types of media the Virtual Operator can analyze.\n\nDisable 'Clips' to prevent review of recorded video, or 'Live Video' to prevent analysis of live camera feeds.",
    eventPermissions: "Control what actions the Virtual Operator can take when processing events.\n\n'Actions' allows completion of Action Plan steps, 'Escalate' allows escalation to human operators, and 'Closing' allows event closure.",
    virtualAssist: "AI-powered assistance for summarizing events inside the Event Close dialog. Input your desired minimum and maximum word count.",
    virtualOperators: "Automated operators that can process events.\n\nThis toggle switch Disables/Enables all Virtual Operators",
    capacity: "Settings to control how many Virtual Operators can run simultaneously.\n\ne.g. how many events can be processed concurrently",
    additionalContext: "Extra information or instructions provided to Virtual Operators when processing events.\n\nUsed to direct the behavior of the Language Model, or provide organization-specific context.",
    firstAction: "Time to wait after picking up an event before taking the first step.\n\nThis time is typically used to let clips and live video be viewed and processed by the Virtual Operator.\n\nFor example, if no video or clips were expected at all, it could be set to 0 seconds.\n\nIf live video is irrelevant, and only clips are important, set this to approximately the expected length of the clip, in seconds.\n\nOr, if you want the VO to spend time reviewing the live video, set the value for however long you would like it to do so.",
    watchVideo: "Allow your Virtual Operators to independantly decide to continue monitoring video feeds longer than 'Seconds Before First Action'.\n\n'Min Time' is the smallest amount of time it can decide to continue watching video for an individual step, while 'Max Time' is the largest.\n\nFinally, 'Max Total Time' is the longest total amount of time the VO will be allowed to wait for a given event.",
    guardDispatch: "Allow the Virtual Operator to independantly decide to dispatch guards when needed.\n\n'Max Dist' determines the maximum distance a Guard can be from an event to be considered for dispatch.\n\n'Multi-Share' determines if the VO will share an event to a guard if that guard already has an Active Event Share.",
    alarmContent: "Control how Virtual Operators analyze different types of alarm content for SMTP alarms.\n\nEnabling 'Text' will include any text from the body of the email in the content provided to the Language Model.\n\nEnabling 'Media' will ensure images present in the body of an email alarm are provided to the to the language model.",
  };
  const baseUrl = 'https://virtualoperator.eastus.cloudapp.azure.com/api';  //  'http://127.0.0.1:5001';  // 

  const toggleGuidelinesModal = () => {
    setGuidelinesModalOpen(!guidelinesModalOpen);
  }

  const togglePickupSettingsModal = () => {
    setPickupSettingsModalOpen(!pickupSettingsModalOpen);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const tenantId = url.searchParams.get('tenantGroupId');
    setTenantGroupId(tenantId);
    const regionCode = url.searchParams.get('region');
    setRegion(regionCode);
    if (!tenantId || !regionCode) {
      setError("No Tenant Specified");
    }
  }, []);

  useEffect(() => {
    if (tenantGroupId && region) {
      axios
        .get(`${baseUrl}/getSettings?tenantGroupId=${tenantGroupId}&region=${region}`)
        .then(response => {
          if (response.data.error) {
            throw new Error(response.data.error);
          }
          const { storedGuidance, timeToWait } = response.data;
          setShowModelSelection(response.data.showModelSelection);
          setUseGpt4(response.data.useGpt4);
          setStoredUseGpt4(response.data.useGpt4);
          setGeneralGuidance(storedGuidance);
          setSecondsToWait(timeToWait);
          setStoredGeneralGuidance(storedGuidance);
          setStoredSecondsToWait(timeToWait);
          setIncludeEmailMedia(response.data.includeEmailMedia);
          setStoredIncludeEmailMedia(response.data.includeEmailMedia);
          setIncludeEmailText(response.data.includeEmailText);
          setStoredIncludeEmailText(response.data.includeEmailText);
          setStoredAllowDispatchGuards(response.data.allowDispatchGuards);
          setAllowDispatchGuards(response.data.allowDispatchGuards);
          setStoredAllowMultiDispatch(response.data.allowMultiDispatch);
          setAllowMultiDispatch(response.data.allowMultiDispatch);
          setStoredMaxDispatchDistance(response.data.maxDispatchDistance);
          setMaxDispatchDistance(response.data.maxDispatchDistance);
          setStoredAllowWaitFunction(response.data.allowWaitFunction);
          setAllowWaitFunction(response.data.allowWaitFunction);
          setStoredMaxWaitSeconds(response.data.maxWaitSeconds);
          setMaxWaitSeconds(response.data.maxWaitSeconds);
          setStoredMinWaitSeconds(response.data.minWaitSeconds);
          setMinWaitSeconds(response.data.minWaitSeconds);
          setStoredTotalMaxWaitSeconds(response.data.totalMaxWaitSeconds);
          setTotalMaxWaitSeconds(response.data.totalMaxWaitSeconds);
          setVirtualAssistEnabled(response.data.virtualAssistEnabled);
          setStoredVirtualAssistEnabled(response.data.virtualAssistEnabled);
          setVirtualOperatorEnabled(response.data.virtualOperatorEnabled);
          setStoredVirtualOperatorEnabled(response.data.virtualOperatorEnabled);
          setStoredMaxSummaryWords(response.data.maxSummaryWords);
          setMaxSummaryWords(response.data.maxSummaryWords);
          setStoredMinSummaryWords(response.data.minSummaryWords);
          setMinSummaryWords(response.data.minSummaryWords);
          setAllowPausing(response.data.allowPausing);
          setStoredAllowPausing(response.data.allowPausing);
          setNumberOfOperators(response.data.numberOfOperators);
          setStoredNumberOfOperators(response.data.numberOfOperators);
          setEnableClips(response.data.enable_clips);
          setStoredEnableClips(response.data.enable_clips);
          setEnableLiveVideo(response.data.enable_live_video);
          setStoredEnableLiveVideo(response.data.enable_live_video);
          setCanCompleteActions(response.data.can_complete_actions);
          setStoredCanCompleteActions(response.data.can_complete_actions);
          setCanEscalate(response.data.can_escalate);
          setStoredCanEscalate(response.data.can_escalate);
          setCanCloseEvent(response.data.can_close_event);
          setStoredCanCloseEvent(response.data.can_close_event);
          setHasChanges(false);
          setError(null);
        })
        .catch(error => {
          console.error(error);
          if (error.message === "Network Error") {
            setError("Error: Virtual Operator Manager Offline");
          } else {
            setError("Error: " + error.message);
          }
        });
    }
  }, [tenantGroupId, region]);

  useEffect(() => {
    if (((generalGuidance || "") !== (storedGeneralGuidance || "")) || secondsToWait !== storedSecondsToWait) {
      setHasChanges(true);
    } else if (allowDispatchGuards !== storedAllowDispatchGuards || maxDispatchDistance !== storedMaxDispatchDistance || allowMultiDispatch !== storedAllowMultiDispatch) {
      setHasChanges(true);
    } else if (allowWaitFunction !== storedAllowWaitFunction || maxWaitSeconds !== storedMaxWaitSeconds || totalMaxWaitSeconds !== storedTotalMaxWaitSeconds || minWaitSeconds !== storedMinWaitSeconds) {
      setHasChanges(true);
    } else if (useGpt4 !== storedUseGpt4) {
      setHasChanges(true);
    } else if (includeEmailMedia !== storedIncludeEmailMedia || includeEmailText !== storedIncludeEmailText) {
      setHasChanges(true);
    } else if (virtualAssistEnabled !== storedVirtualAssistEnabled || virtualOperatorEnabled !== storedVirtualOperatorEnabled) {
      setHasChanges(true);
    } else if (minSummaryWords !== storedMinSummaryWords || maxSummaryWords !== storedMaxSummaryWords) {
      setHasChanges(true);
    } else if (allowPausing !== storedAllowPausing || numberOfOperators !== storedNumberOfOperators) {
      setHasChanges(true);
    } else if (enableClips !== storedEnableClips || enableLiveVideo !== storedEnableLiveVideo || canCompleteActions !== storedCanCompleteActions || canEscalate !== storedCanEscalate || canCloseEvent !== storedCanCloseEvent) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [
    generalGuidance,
    secondsToWait,
    allowDispatchGuards,
    allowMultiDispatch,
    maxDispatchDistance,
    allowWaitFunction,
    maxWaitSeconds,
    totalMaxWaitSeconds,
    minWaitSeconds,
    useGpt4,
    storedGeneralGuidance,
    storedSecondsToWait,
    storedAllowDispatchGuards,
    storedAllowMultiDispatch,
    storedMaxDispatchDistance,
    storedAllowWaitFunction,
    storedMaxWaitSeconds,
    storedTotalMaxWaitSeconds,
    storedMinWaitSeconds,
    storedUseGpt4,
    includeEmailMedia,
    storedIncludeEmailMedia,
    includeEmailText,
    storedIncludeEmailText,
    virtualAssistEnabled,
    storedVirtualAssistEnabled,
    virtualOperatorEnabled,
    storedVirtualOperatorEnabled,
    minSummaryWords,
    storedMinSummaryWords,
    maxSummaryWords,
    storedMaxSummaryWords,
    allowPausing,
    storedAllowPausing,
    numberOfOperators,
    storedNumberOfOperators,
    enableClips,
    storedEnableClips,
    enableLiveVideo,
    storedEnableLiveVideo,
    canCompleteActions,
    storedCanCompleteActions,
    canEscalate,
    storedCanEscalate,
    canCloseEvent,
    storedCanCloseEvent,
  ]);

  const updateSettings = () => {
    if (hasChanges) {
      axios.post(`${baseUrl}/updateSettings?tenantGroupId=${tenantGroupId}&region=${region}`, {
        generalGuidance,
        secondsToWait,
        allowDispatchGuards,
        allowMultiDispatch,
        maxDispatchDistance,
        allowWaitFunction,
        maxWaitSeconds,
        minWaitSeconds,
        totalMaxWaitSeconds,
        useGpt4,
        includeEmailMedia,
        includeEmailText,
        virtualAssistEnabled,
        virtualOperatorEnabled,
        minSummaryWords,
        maxSummaryWords,
        allowPausing,
        numberOfOperators,
        enable_clips: enableClips,
        enable_live_video: enableLiveVideo,
        can_complete_actions: canCompleteActions,
        can_escalate: canEscalate,
        can_close_event: canCloseEvent,
      })
        .then(response => {
          if (response.data.error) {
            throw new Error(response.data.error);
          }
          setUseGpt4(useGpt4);
          setStoredUseGpt4(useGpt4);
          setGeneralGuidance(generalGuidance);
          setSecondsToWait(secondsToWait);
          setStoredGeneralGuidance(generalGuidance);
          setStoredSecondsToWait(secondsToWait);
          setIncludeEmailMedia(includeEmailMedia);
          setStoredIncludeEmailMedia(includeEmailMedia);
          setIncludeEmailText(includeEmailText);
          setStoredIncludeEmailText(includeEmailText);
          setStoredAllowDispatchGuards(allowDispatchGuards);
          setAllowDispatchGuards(allowDispatchGuards);
          setStoredAllowMultiDispatch(allowMultiDispatch);
          setAllowMultiDispatch(allowMultiDispatch);
          setStoredMaxDispatchDistance(maxDispatchDistance);
          setMaxDispatchDistance(maxDispatchDistance);
          setStoredAllowWaitFunction(allowWaitFunction);
          setAllowWaitFunction(allowWaitFunction);
          setStoredMaxWaitSeconds(maxWaitSeconds);
          setMaxWaitSeconds(maxWaitSeconds);
          setStoredMinWaitSeconds(minWaitSeconds);
          setMinWaitSeconds(minWaitSeconds);
          setStoredTotalMaxWaitSeconds(totalMaxWaitSeconds);
          setTotalMaxWaitSeconds(totalMaxWaitSeconds);
          setVirtualAssistEnabled(virtualAssistEnabled);
          setStoredVirtualAssistEnabled(virtualAssistEnabled);
          setVirtualOperatorEnabled(virtualOperatorEnabled);
          setStoredVirtualOperatorEnabled(virtualOperatorEnabled);
          setStoredMaxSummaryWords(maxSummaryWords);
          setMaxSummaryWords(maxSummaryWords);
          setStoredMinSummaryWords(minSummaryWords);
          setMinSummaryWords(minSummaryWords);
          setAllowPausing(allowPausing);
          setStoredAllowPausing(allowPausing);
          setNumberOfOperators(numberOfOperators);
          setStoredNumberOfOperators(numberOfOperators);
          setStoredEnableClips(enableClips);
          setStoredEnableLiveVideo(enableLiveVideo);
          setStoredCanCompleteActions(canCompleteActions);
          setStoredCanEscalate(canEscalate);
          setStoredCanCloseEvent(canCloseEvent);
          setHasChanges(false);
          setError(null);
        })
        .catch(error => {
          console.error(error);
          if (error.message === "Network Error") {
            setError("Settings Manager Offline");
          } else {
            setError("Error: " + error.message);
          }
        });
    }
  }

  return (
    <div className='h-fit min-h-screen text-white bg-neutral-900 py-3'>


      {error ? (
        <div className="mx-6 bg-neutral-800 p-4 pt-3 rounded-lg shadow-lg">
          <div className="text-red-500 text-lg font-bold mb-0 mt-1">{error}</div>
        </div>
      ) : (
        <>


          <div className="mx-6 flex space-x-4">
            <button className={`px-5 py-2 rounded-t-lg ${activeTab === 'response' ? 'bg-neutral-800 text-white font-semibold' : 'bg-neutral-700 text-gray-200'}`} onClick={() => setActiveTab('response')}>Response</button>
            <button className={`px-5 py-2 rounded-t-lg ${activeTab === 'cases' ? 'bg-neutral-800 text-white font-semibold' : 'bg-neutral-700 text-gray-200'}`} onClick={() => setActiveTab('cases')}>Cases</button>
          </div>
          {tenantGroupId &&
            <div className="h-fit bg-neutral-800 flex flex-col pt-2 pb-3 rounded-b-lg rounded-tr-lg mx-6 pl-2 shadow-lg overflow-visible">
              {activeTab === 'response' ? (
                <>
                  <div>
                    <div className="flex items-center">
                      <SectionHeaderTooltip
                        title="Virtual Assist"
                        tooltip={tooltips.virtualAssist}
                        className="ml-4 mt-2 mb-2"
                        size="large"
                      />
                      <Toggle
                        className="ml-4"
                        checked={virtualAssistEnabled}
                        onChange={(e) => setVirtualAssistEnabled(e.target.checked)}
                      />
                    </div>
                    <div className="flex flex-col pl-6">
                      <div className="flex space-x-6">
                        <div>
                          <label htmlFor="minWaitTime" className="text-xs mb-1 block">Min Word Count</label>
                          <input
                            type="number"
                            id="minWaitTime"
                            className={`w-20 px-3 py-2 text-gray-700 border bg-neutral-200 rounded-lg focus:outline-none`}
                            value={minSummaryWords}
                            onChange={(e) => setMinWaitSeconds(parseInt(e.target.value))}
                          />
                        </div>
                        <div>
                          <label htmlFor="maxWaitTime" className="text-xs mb-1 block">Max Word Count</label>
                          <input
                            type="number"
                            id="maxWaitTime"
                            className={`w-20 px-3 py-2 text-gray-700 border bg-neutral-200 rounded-lg focus:outline-none`}
                            value={maxSummaryWords}
                            onChange={(e) => setMaxWaitSeconds(parseInt(e.target.value))}
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className='mt-3'>
                    <div className="flex items-center">
                      <SectionHeaderTooltip
                        title="Virtual Operators"
                        tooltip={tooltips.virtualOperators}
                        className="ml-4 mt-2 mb-2"
                        size="large"
                      />
                      <Toggle
                        className="ml-4"
                        checked={virtualOperatorEnabled}
                        onChange={(e) => setVirtualOperatorEnabled(e.target.checked)}
                      />
                    </div>
                    <div className=" flex space-x-6">
                      <div className="flex flex-col pl-6">
                        <SectionHeaderTooltip
                          title="Capacity"
                          tooltip={tooltips.capacity}
                          className="text-sm font-semibold mb-1"
                        />
                        <div className="flex space-x-6">
                          <div className="flex flex-col items-center ml-2">
                            <label htmlFor="allowDispatch" className="text-xs mb-1 block">Pausing</label>
                            <input
                              type="checkbox"
                              id="allowDispatch"
                              className='mt-3 w-4 h-4 text-green-600 border-0 rounded-md focus:ring-0'
                              checked={allowPausing}
                              onChange={(e) => setAllowPausing(e.target.checked)}
                            />
                          </div>
                          <div>
                            <label htmlFor="minWaitTime" className="text-xs mb-1 block"># of Operators</label>
                            <input
                              type="number"
                              id="minWaitTime"
                              className={`w-20 px-3 py-2 text-gray-700 border bg-neutral-200 rounded-lg focus:outline-none`}
                              value={numberOfOperators}
                              onChange={(e) => setNumberOfOperators(parseInt(e.target.value))}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-6 mt-3">
                      <SectionHeaderTooltip
                        title="Additional Context"
                        tooltip={tooltips.additionalContext}
                        className="text-sm font-semibold mb-3"
                      />
                      <textarea
                        className="w-full px-3 py-2 text-gray-700 border bg-neutral-200 rounded-lg focus:outline-none"
                        rows="3"
                        value={generalGuidance}
                        onChange={(e) => setGeneralGuidance(e.target.value)}
                      />
                    </div>
                    <div className="px-6 pt-3 flex justify-left items-end">
                      <button
                        className={`px-4 py-2 rounded-md bg-neutral-500 ${hasChanges ? 'hover:bg-neutral-600' : 'opacity-50'}`}
                        onClick={updateSettings}
                        disabled={!hasChanges}
                      >
                        Update Settings
                      </button>
                      {hasChanges && <div className="ml-4 text-xs italic text-neutral-400">You have unsaved changes</div>}
                    </div>
                    <div className="flex space-x-6 mt-3">
                      <div className="flex items-center ml-4 cursor-pointer" onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}>
                        <p className={`text-sm font-semibold mt-3 mb-2`}>Advanced Settings</p>
                        <svg
                          className={`m-2 h-4 w-4 mb-0 transition-transform duration-200 ease-in-out transform ${showAdvancedSettings ? 'rotate-90' : ''}`}
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                    {showAdvancedSettings &&
                      <>
                        <div className="flex space-x-6">
                          <div className="px-6">
                            <SectionHeaderTooltip
                              title="Seconds Before First Action"
                              tooltip={tooltips.firstAction}
                              className="text-sm font-semibold mb-3"
                            />
                            <input
                              type="number"
                              className="ml-4 w-20 px-3 py-2 text-gray-700 border bg-neutral-200 rounded-lg focus:outline-none"
                              value={secondsToWait}
                              onChange={(e) => setSecondsToWait(parseInt(e.target.value))}
                            />
                          </div>
                        </div>
                        <div className="mt-3 flex space-x-6">
  <div className="flex flex-col pl-6">
    <SectionHeaderTooltip
      title="Event Media"
      tooltip={tooltips.eventMedia}
      className="text-sm font-semibold mb-1"
    />
    <div className="flex space-x-6">
      <div className="flex flex-col items-center ml-2">
        <label htmlFor="enableClips" className="text-xs mb-1 block">Clips</label>
        <input
          type="checkbox"
          id="enableClips"
          className='mt-3 w-4 h-4 text-green-600 border-0 rounded-md focus:ring-0'
          checked={enableClips}
          onChange={(e) => setEnableClips(e.target.checked)}
        />
      </div>
      <div className="flex flex-col items-center ml-2">
        <label htmlFor="enableLiveVideo" className="text-xs mb-1 block">Live Video</label>
        <input
          type="checkbox"
          id="enableLiveVideo"
          className='mt-3 w-4 h-4 text-green-600 border-0 rounded-md focus:ring-0'
          checked={enableLiveVideo}
          onChange={(e) => setEnableLiveVideo(e.target.checked)}
        />
      </div>
    </div>
  </div>
</div>

<div className="mt-3 flex space-x-6">
  <div className="flex flex-col pl-6">
    <SectionHeaderTooltip
      title="Event Permissions"
      tooltip={tooltips.eventPermissions}
      className="text-sm font-semibold mb-1"
    />
    <div className="flex space-x-6">
      <div className="flex flex-col items-center ml-2">
        <label htmlFor="canCompleteActions" className="text-xs mb-1 block">Actions</label>
        <input
          type="checkbox"
          id="canCompleteActions"
          className='mt-3 w-4 h-4 text-green-600 border-0 rounded-md focus:ring-0'
          checked={canCompleteActions}
          onChange={(e) => setCanCompleteActions(e.target.checked)}
        />
      </div>
      <div className="flex flex-col items-center ml-2">
        <label htmlFor="canEscalate" className="text-xs mb-1 block">Escalate</label>
        <input
          type="checkbox"
          id="canEscalate"
          className='mt-3 w-4 h-4 text-green-600 border-0 rounded-md focus:ring-0'
          checked={canEscalate}
          onChange={(e) => setCanEscalate(e.target.checked)}
        />
      </div>
      <div className="flex flex-col items-center ml-2">
        <label htmlFor="canCloseEvent" className="text-xs mb-1 block">Closing</label>
        <input
          type="checkbox"
          id="canCloseEvent"
          className='mt-3 w-4 h-4 text-green-600 border-0 rounded-md focus:ring-0'
          checked={canCloseEvent}
          onChange={(e) => setCanCloseEvent(e.target.checked)}
        />
      </div>
    </div>
  </div>
</div>
                        <div className="mt-3 flex space-x-6">
                          <div className="flex flex-col pl-6">
                            <SectionHeaderTooltip
                              title="Continue Watching Video"
                              tooltip={tooltips.watchVideo}
                              className="text-sm font-semibold mb-1"
                            />
                            <div className="flex space-x-6">
                              <div className="flex flex-col items-center ml-2">
                                <label htmlFor="allowDispatch" className="text-xs mb-1 block">Enabled</label>
                                <input
                                  type="checkbox"
                                  id="allowDispatch"
                                  className='mt-3 w-4 h-4 text-green-600 border-0 rounded-md focus:ring-0'
                                  checked={allowWaitFunction}
                                  onChange={(e) => setAllowWaitFunction(e.target.checked)}
                                />
                              </div>
                              <div>
                                <label htmlFor="minWaitTime" className="text-xs mb-1 block">Min Time (s)</label>
                                <input
                                  type="number"
                                  id="minWaitTime"
                                  className={`w-20 px-3 py-2 text-gray-700 border bg-neutral-200 rounded-lg focus:outline-none ${allowWaitFunction ? "" : "opacity-50"}`}
                                  value={minWaitSeconds}
                                  disabled={!allowWaitFunction}
                                  onChange={(e) => setMinWaitSeconds(parseInt(e.target.value))}
                                />
                              </div>
                              <div>
                                <label htmlFor="maxWaitTime" className="text-xs mb-1 block">Max Time (s)</label>
                                <input
                                  type="number"
                                  id="maxWaitTime"
                                  className={`w-20 px-3 py-2 text-gray-700 border bg-neutral-200 rounded-lg focus:outline-none ${allowWaitFunction ? "" : "opacity-50"}`}
                                  value={maxWaitSeconds}
                                  disabled={!allowWaitFunction}
                                  onChange={(e) => setMaxWaitSeconds(parseInt(e.target.value))}
                                />
                              </div>
                              <div>
                                <label htmlFor="totalMaxWaitTime" className="text-xs mb-1 block">Max Total Time (s)</label>
                                <input
                                  type="number"
                                  id="totalMaxWaitTime"
                                  className={`w-20 px-3 py-2 text-gray-700 border bg-neutral-200 rounded-lg focus:outline-none ${allowWaitFunction ? "" : "opacity-50"}`}
                                  value={totalMaxWaitSeconds}
                                  disabled={!allowWaitFunction}
                                  onChange={(e) => setTotalMaxWaitSeconds(parseInt(e.target.value))}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-1 flex space-x-6">
                          <div className="flex flex-col px-6 pt-3">
                            <SectionHeaderTooltip
                              title="Guard Dispatch"
                              tooltip={tooltips.guardDispatch}
                              className="text-sm font-semibold mb-1"
                            />
                            <div className="flex space-x-6">
                              <div className="flex flex-col items-center ml-2">
                                <label htmlFor="allowDispatch" className="text-xs mb-1 block">Enabled</label>
                                <input
                                  type="checkbox"
                                  id="allowDispatch"
                                  className='mt-3 w-4 h-4 text-green-600 border-0 rounded-md focus:ring-0'
                                  checked={allowDispatchGuards}
                                  onChange={(e) => setAllowDispatchGuards(e.target.checked)}
                                />
                              </div>

                              <div>
                                <label htmlFor="maxDistance" className="text-xs mb-1 block">Max Dist (km)</label>
                                <input
                                  type="number"
                                  id="maxDistance"
                                  className={`w-20 px-3 py-2 text-gray-700 border bg-neutral-200 rounded-lg focus:outline-none ${allowDispatchGuards ? "" : "opacity-50"}`}
                                  value={maxDispatchDistance}
                                  disabled={!allowDispatchGuards}
                                  onChange={(e) => setMaxDispatchDistance(parseInt(e.target.value))}
                                />
                              </div>
                              <div className="flex flex-col items-center ml-2">
                                <label htmlFor="allowDispatch" className="text-xs mb-1 block">Multi-Share</label>
                                <input
                                  type="checkbox"
                                  id="multiDispatch"
                                  className={`mt-3 w-4 h-4 text-green-600 border-0 rounded-md focus:ring-0 ${allowDispatchGuards ? "" : "opacity-50"}`}
                                  checked={allowMultiDispatch}
                                  disabled={!allowDispatchGuards}
                                  onChange={(e) => setAllowMultiDispatch(e.target.checked)}
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="mt-3 flex space-x-6">
                          <div className="flex flex-col pl-6">
                            <SectionHeaderTooltip
                              title="Analyze Alarm Body"
                              tooltip={tooltips.alarmContent}
                              className="text-sm font-semibold mb-1"
                            />
                            <div className="flex space-x-6">
                              <div className="flex flex-col items-center ml-2">
                                <label htmlFor="textEmails" className="text-xs mb-1 block">Text</label>
                                <input
                                  type="checkbox"
                                  id="textEmails"
                                  className='mt-3 w-4 h-4 text-green-600 border-0 rounded-md focus:ring-0'
                                  checked={includeEmailText}
                                  onChange={(e) => setIncludeEmailText(e.target.checked)}
                                />
                              </div>
                              <div className="flex flex-col items-center ml-2">
                                <label htmlFor="mediaEmails" className="text-xs mb-1 block">Media</label>
                                <input
                                  type="checkbox"
                                  id="mediaEmails"
                                  className='mt-3 w-4 h-4 text-green-600 border-0 rounded-md focus:ring-0'
                                  checked={includeEmailMedia}
                                  onChange={(e) => setIncludeEmailMedia(e.target.checked)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {showModelSelection && (

                          <div className="mt-3 flex space-x-6">
                            <div className="flex flex-col px-6">
                              <p className="text-sm font-semibold mb-2">Decision Engine</p>
                              <div>
                                <input
                                  type="radio"
                                  id="GPT 3.5"
                                  name="model"
                                  className='mr-2 text-green-600'
                                  checked={!useGpt4}
                                  onChange={(e) => setUseGpt4(!e.target.checked)}
                                />
                                <label htmlFor="GPT3.5">GPT 3.5</label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  id="GPT 4"
                                  name="model"
                                  className='mr-2 text-green-600'
                                  checked={useGpt4}
                                  onChange={(e) => setUseGpt4(e.target.checked)}
                                />
                                <label htmlFor="GPT4">GPT 4</label>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="px-6 pt-3 flex justify-left items-end">
                          <button
                            className={`px-4 py-2 h-fit mr-4 mt-1 rounded-md bg-neutral-500 hover:bg-neutral-600 whitespace-nowrap`}
                            onClick={toggleGuidelinesModal}
                          >
                            Edit Guidelines
                          </button>
                          <button
                            className={`px-4 py-2 h-fit mr-4 mt-1 rounded-md bg-neutral-500 hover:bg-neutral-600 whitespace-nowrap`}
                            onClick={togglePickupSettingsModal}
                          >
                            Pickup Settings
                          </button>
                          <button
                            className={`px-4 py-2 rounded-md mt-1 bg-neutral-500 ${hasChanges ? 'hover:bg-neutral-600' : 'opacity-50'}`}
                            onClick={updateSettings}
                            disabled={!hasChanges}
                          >
                            Update Settings
                          </button>
                          {hasChanges && <div className="ml-4 text-xs italic text-neutral-400">You have unsaved changes</div>}
                        </div>
                      </>
                    }

                  </div>
                </>
              ) : (
                <div>
                  {/* Cases placeholder */}
                  <p className='mt-2 ml-4 text-lg font-semibold'>Cases settings coming soon...</p>
                </div>
              )}
            </div>
          }

          {tenantGroupId && region &&
            <GuidelinesModal
              isOpen={guidelinesModalOpen}
              toggle={toggleGuidelinesModal}
              tenantGroupId={tenantGroupId}
              baseUrl={baseUrl}
              region={region}
            />
          }
          {tenantGroupId && region &&
            <PickupSettingsModal
              isOpen={pickupSettingsModalOpen}
              toggle={togglePickupSettingsModal}
              tenantGroupId={tenantGroupId}
              baseUrl={baseUrl}
              region={region}
            />
          }

        </>
      )}


    </div>
  );
}

export default Settings;

